<template>
  <v-dialog :value="showDialog" max-width="30rem" width="100%">
    <v-card>
      <v-toolbar class="pt-1" dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title class="px-2 px-md-5 d-flex justify-center">
        <h2 class="headline font-weight-medium mb-2 text-center">
          {{ $t('contract.visibility') }}
        </h2>
      </v-card-title>

      <v-card-text class="px-4 pb-5" :style="loading ? 'text-align: center' : ''">
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        <v-row v-else align="center" justify="center" no-gutters>
          <v-col
            v-for="signIdentity in signIdentitiesVisibility"
            :key="signIdentity.id"
            class="pa-4"
            :class="`col-${$vuetify.breakpoint.mobile ? 12 : 10}`"
            :style="`${signIdentity.isVisible ? 'opacity: 0.5' : 'opacity: 1'}`"
          >
            <v-row align="center" class="sign-identity__box">
              <v-col cols="auto">
                <SAvatar
                  :size="40"
                  :firstname="signIdentity.firstname"
                  :lastname="signIdentity.lastname"
                  :profile-image="signIdentity.avatar_url"
                />
              </v-col>

              <v-col class="px-1">
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <span
                      class="sign-identity__name font-weight-bold"
                      :class="{
                        'subtitle-1': $vuetify.breakpoint.mobile,
                        title: !$vuetify.breakpoint.mobile,
                      }"
                    >
                      {{ signIdentity.firstname }} {{ signIdentity.lastname }}
                    </span>
                  </v-col>

                  <v-col cols="12">
                    <span class="subtitle-2 sign-identity__description font-weight-regular">
                      {{ signIdentity.organization_name || $t('general.signature') }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="auto">
                <v-btn icon :id="signIdentity.id" @click="toggleVisibility(signIdentity)">
                  <v-icon>{{ `mdi-eye-${signIdentity.isVisible ? 'off-' : ''}outline` }}</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="auto" v-if="isSignPlaced(signIdentity)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-check</v-icon>
                  </template>
                  <span>{{ $t('contract.visibility.tooltip') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex align-center justify-center">
        <v-btn color="primary" x-large min-width="10rem" class="mb-5" @click="confirmAndClose">{{
          $t('general.save')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SAvatar from '@/common/components/SAvatar';
import { internalApiRequest } from '@/shared/services/apiService';
import { useContractSignaturesPlaceholdersStore } from '@/features/contract/stores/contractSignaturesPlaceholdersStore';
import { computed } from 'vue';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';

export default {
  name: 'ContractVisibilityManagement',
  components: {
    SAvatar,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    value: Boolean,
  },
  data() {
    return {
      counterpartySignIdentities: [],
      signIdentitiesVisibility: [],
      loading: false,
    };
  },
  setup() {
    const contractSignaturesPlaceholders = useContractSignaturesPlaceholdersStore();

    function isSignPlaced(signIdentity) {
      for (let identityId in this.getAllSignIdentities) {
        if (identityId == signIdentity.id) {
          return (
            !!this.getAllSignIdentities[identityId]?.positions[0]?.page ||
            0 === this.getAllSignIdentities[identityId]?.positions[0]?.page
          );
        }
      }
    }

    return {
      getAllSignIdentities: computed(() => contractSignaturesPlaceholders.getAllSignIdentities),
      isSignPlaced,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    this.fetchAllSignIdentities();
  },
  methods: {
    fetchAllSignIdentities() {
      this.loading = true;
      internalApiRequest({
        method: 'GET',
        path: `api/v2/contract/${this.contract.id}/signIdentity/list?showDeleted=true`,
      }).then((response) => {
        this.loading = false;
        this.counterpartySignIdentities = response.filter(
          (signIdentity) => !signIdentity.is_proposer,
        );
        return (this.signIdentitiesVisibility = this.counterpartySignIdentities.map(
          (signIdentity) =>
            Object.assign(
              {},
              {
                id: signIdentity.id,
                isVisible: !!signIdentity.deleted_at,
                firstname: signIdentity.firstname,
                lastname: signIdentity.lastname,
              },
            ),
        ));
      });
    },
    toggleVisibility(signIdentity) {
      if (!signIdentity.isVisible) {
        return internalApiRequest({
          method: 'DELETE',
          path: `api/v2/contract/${this.contract.id}/signIdentity/${signIdentity.id}`,
        })
          .then(() => (signIdentity.isVisible = !signIdentity.isVisible))
          .catch((err) => getErrorResponseMessage(err));
      }

      return internalApiRequest({
        method: 'PATCH',
        path: `api/v2/contract/${this.contract.id}/signIdentity/${signIdentity.id}`,
        data: { deletedAt: null },
      })
        .then(() => (signIdentity.isVisible = !signIdentity.isVisible))
        .catch((err) => getErrorResponseMessage(err));
    },
    confirmAndClose() {
      this.showDialog = false;
      return location.reload();
    },
    closeModal() {
      const isVisibilityChanged = this.counterpartySignIdentities.find(
        (signIdentity, index) =>
          !!signIdentity?.deleted_at !== this.signIdentitiesVisibility[index]?.isVisible,
      );

      if (isVisibilityChanged) {
        this.showDialog = false;
        return location.reload();
      }

      return (this.showDialog = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-identity__box {
  border: 1px solid #dfe7f3;
  border-radius: 6px;
}

.sign-identity__name {
  color: #5e239e;
  letter-spacing: -0.03px;
}

.sign-identity__description {
  color: #8e97a4;
  letter-spacing: -0.03px;
}
</style>
